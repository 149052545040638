.footer {
	padding: 40px 0;
	background-color: $footer;
	color: #fff;
	@media screen and (max-width: 991px) {
		padding: 10px 0 25px 0; } }

.footer .footer-links-row .col-4,
.footer .footer-links-row .col-5,
.footer .footer-links-row .col-3 {
	padding-left: 0; }

//.footer .footer-links-row .col-4
//media screen and (max-width: 580px)
//padding-left: 5px


.footer-links-row .footer-link:last-child a {
	padding: 7px 0 0 0; }

.footer-link a {
	@include menuLink(#fff);
	font-size: 15px;
	font-family: $openSans;
	font-weight: 300;
	text-decoration: none;
	padding: 7px 0;
	text-transform: uppercase;
	color: #fff;
	display: inline-block;
	@media screen and (max-width: 1200px) {
		font-size: 14px;
		padding: 5px 0; }
	@media screen and (max-width: 768px) {
		font-size: 13px; }
	@media screen and (max-width: 480px) {
		font-size: 12px; }
	@media screen and (max-width: 430px) {
		font-size: 11px; }
	@media screen and (max-width: 380px) {
		font-size: 9.5px; }
	@media screen and (max-width: 340px) {
		font-size: 8px; } }


.footer-link:hover .foter-arr {
	left: -1px; }

.foter-arr {
	display: inline-block;
	position: relative;
	left: -3px;
	transition: all .2s ease-out; }


.footer-copy {
	margin: 14px auto 7px auto;
	font-family: $frank;
	font-size: 14px; }


.to-top-row {
	padding-bottom: 25px;
	@media screen and (max-width: 991px) {
		padding-bottom: 0; } }

.to-top-ctn {
	text-align: right; }

.active-toTop {
	@media screen and (max-width: 991px) {
		top: 15px; } }

#toTop {
	display: inline-block;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
	position: relative;
	top: -6px;
	@media screen and (max-width: 991px) {
		top: 20px; } }

#toTop .bord {
	display: inline-block;
	vertical-align: middle;
	font-size: 40px;
	cursor: pointer;
	width: 55px;
	height: 55px;
	line-height: 53px;
	text-align: center;
	position: relative;
	z-index: 1;
	border: 2px solid #fff;
	border-radius: 50%;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
	color: #fff;
	@media screen and (max-width: 768px) {
		width: 40px;
		height: 40px;
		line-height: 38px;
		font-size: 30px; } }

#toTop .bord:before {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: $footer;
    border: 2px solid #fff;
    border-radius: 50%;
    box-sizing: content-box;
    opacity: 0.5;
    top: -2px;
    left: -2px;
    z-index: -1;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s; }


#toTop:hover .bord:before, #toTop:active .bord:before, #toTop:focus .bord:before {
    background: #fff; }

#toTop:hover .bord, #toTop:active .bord, #toTop:focus .bord {
	background: #fff;
	color: $footer; }


.footer-img-ctn {
	@media screen and (max-width: 991px) {
		margin-bottom: 30px; } }

.footer-img-ctn img {
	width: 52%;
	@media screen and (max-width: 1200px) {
		width: 50%; }
	@media screen and (max-width: 991px) {
		width: 40%; } }

.footer-logo-tagline {
	font-size: 22px;
	color: #fff;
	font-family: $openSans;
	font-weight: 300;
	@media screen and (max-width: 1200px) {
		font-size: 21px; }
	@media screen and (max-width: 768px) {
		font-size: 18px; }
	@media screen and (max-width: 540px) {
		font-size: 17px; }
	@media screen and (max-width: 440px) {
		font-size: 15px; }
	@media screen and (max-width: 380px) {
		font-size: 13px; }
	@media screen and (max-width: 340px) {
		font-size: 12px; } }

.footer-copy {
	background-color: #fff;
	text-align: center;
	font-size: 17px;
	font-weight: 300;
	font-family: $openSans;
	color: $footer;
	line-height: 1;
	padding-bottom: 3px;
	text-transform: uppercase; }
