@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius:    $radius;
  -ms-border-radius:     $radius;
  border-radius:         $radius; }


@mixin buttonEfects($backgroundColor, $textColor) {
	&:hover,&:active,&:focus {
		color: $textColor;
		background-color: $backgroundColor;
		-webkit-box-shadow: 0 0 8px rgba(0,0,0,0.2);
		-moz-box-shadow: 0 0 8px rgba(0,0,0,0.2);
		-ms-box-shadow: 0 0 8px rgba(0,0,0,0.2) {
		 box-shadow: 0 0 8px rgba(0,0,0,0.2); } }
	-webkit-transition: all 250ms ease;
	-moz-transition: all 250ms ease;
	-ms-transition: all 250ms ease;
	transition: all 250ms ease; }

@mixin hoverEffect() {
	&:hover,&:active,&:focus {
		-webkit-box-shadow: 0 0 8px rgba(0,0,0,0.18);
		-moz-box-shadow: 0 0 8px rgba(0,0,0,0.18);
		-ms-box-shadow: 0 0 8px rgba(0,0,0,0.18) {
		 box-shadow: 0 0 8px rgba(0,0,0,0.18); } }
	-webkit-transition: all 250ms ease;
	-moz-transition: all 250ms ease;
	-ms-transition: all 250ms ease;
	transition: all 250ms ease; }

@mixin menuLink($hoverColor) {
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	transition: all 200ms ease;
	&:hover,&:active,&:focus {
		color: $hoverColor; } }


@mixin infoBackground($url) {
	background: url($url) no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 350px;
	& p {
		position: absolute;
		top: 50%;
		left: 15%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform:  translateY(-50%);
		transform: translateY(-50%);
		width: 70%;
		text-align: center;
		line-height: 1.5;
		color: #fff;
		font-size: 28px;
		font-weight: 500; }
	@media screen and (max-width: 991px) {
		height: 340px;
		& p {
			font-size: 25px;
			width: 74%;
			left: 13%; } }
	@media screen and (max-width: 768px) {
		height: 320px;
		& p {
			font-size: 21px;
			width: 78%;
			left: 11%; } }
	@media screen and (max-width: 640px) {
		height: 300px;
		& p {
			font-size: 18px;
			width: 84%;
			left: 8%; } }
	@media screen and (max-width: 480px) {
		height: 260px;
		& p {
			font-size: 17px;
			width: 98%;
			left: 1%; } } }

@mixin homeGridBackground($url,$bckSize) {
	background-image: url($url);
	background-repeat: no-repeat;
	background-position: 85% 85%;
	-webkit-background-size: $bckSize;
	-moz-background-size: $bckSize;
	-o-background-size: $bckSize;
	background-size: $bckSize; }

@mixin naslovSekcije() {
	font-family: $frank;
	font-size: 41px;
	color: $textBoja;
	margin: 0 auto;
	font-weight: 500;
	@media screen and (max-width: 1035px) {
		font-size: 39px;
		margin: 45px 0; }
	@media screen and (max-width: 991px) {
		font-size: 36px;
		margin: 40px 0 35px 0;
		text-align: center; }
	@media screen and (max-width: 768px) {
		font-size: 31px;
		margin: 40px 0; }
	@media screen and (max-width: 480px) {
		font-size: 29px;
		margin: 30px 0; }
	@media screen and (max-width: 430px) {
		font-size: 26px;
		margin: 20px 0; } }

@mixin naslovSekcijeSmall($color) {
	font-family: $frank;
	font-size: 31px;
	color: $color;
	margin: 0 auto;
	font-weight: 500;
	@media screen and (max-width: 991px) {
		font-size: 27px;
		margin: 50px 0; }
	@media screen and (max-width: 768px) {
		font-size: 25px;
		margin: 40px 0; }
	@media screen and (max-width: 480px) {
		font-size: 23px;
		margin: 30px 0; }
	@media screen and (max-width: 430px) {
		font-size: 21px;
		margin: 20px 0; } }

@mixin ctaButton($backgroundColor, $textColor) {
	&:hover,&:active,&:focus {
		color: $textColor;
		text-decoration: none !important;
		-webkit-box-shadow: 0 0 8px rgba(0,0,0,0.15);
		-moz-box-shadow: 0 0 8px rgba(0,0,0,0.15);
		-ms-box-shadow: 0 0 8px rgba(0,0,0,0.15);
		box-shadow: 0 0 8px rgba(0,0,0,0.15);
		-webkit-transform: scale(1.035);
		transform: scale(1.035); }
	-webkit-transition: all 280ms ease;
	-moz-transition: all 280ms ease;
	-ms-transition: all 280ms ease;
	transition: all 280ms ease;
	text-transform: uppercase;
	line-height: 1.55;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	text-align: center;
	padding: $ctaLarge;
	font-family: $openSans;
	font-size: 17.5px;
	border: 2px solid $backgroundColor;
	background-color: $backgroundColor;
	text-decoration: none;
	margin: 0 auto;
	color: $textColor;
	display: inline-block;
	font-weight: 600;
	@media screen and (max-width: 991px) {
		font-size: 16px;
		padding: $mediumBtn; }
	@media screen and (max-width: 768px) {
		font-size: 13px;
		padding: $mediumBtn; }
	@media screen and (max-width: 440px) {
		font-size: 12px; } }


@mixin ctaEmptyButton($backgroundColor, $textColor) {
	&:hover,&:active,&:focus {
		color: $textColor;
		background-color: $backgroundColor;
		text-decoration: none !important;
		-webkit-box-shadow: 0 0 8px rgba(0,0,0,0.2);
		-moz-box-shadow: 0 0 8px rgba(0,0,0,0.2);
		-ms-box-shadow: 0 0 8px rgba(0,0,0,0.2) {
		 box-shadow: 0 0 8px rgba(0,0,0,0.2); } }
	-webkit-transition: all 250ms ease;
	-moz-transition: all 250ms ease;
	-ms-transition: all 250ms ease;
	transition: all 250ms ease;
	text-transform: uppercase;
	line-height: 1.55;
	text-align: center;
	padding: $ctaLarge;
	font-family: $openSans;
	font-size: 17.5px;
	font-weight: 600;
	border: 2px solid $backgroundColor;
	background-color: $backgroundColor;
	text-decoration: none;
	margin: 0 auto;
	color: $backgroundColor;
	background-color: $textColor;
	border: 2px solid $backgroundColor;
	display: inline-block;
	@media screen and (max-width: 991px) {
		font-size: 15px;
		padding: $mediumBtn; }
	@media screen and (max-width: 768px) {
		font-size: 13px;
		padding: $mediumBtn; }
	@media screen and (max-width: 440px) {
		font-size: 12px; } }


@mixin contactInfoBackground($url,$pos1,$pos2,$size) {
	background-image: url($url);
	background-repeat: no-repeat;
	background-position: $pos1 $pos2;
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-o-background-size: $size;
	background-size: $size; }

@mixin alignAbsoluteMiddle() {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform:  translateY(-50%);
	transform: translateY(-50%);
	text-align: center; }

@mixin ctaLine($url) {
	background-image: url($url);
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	transform: translateY(-50px);
	width: 100%;
	height: 51px;
	@media screen and (max-width: 991px) {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px); } }

@mixin ctaLineCenter($url) {
	background-image: url($url);
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	transform: translateY(-50px);
	width: 100%;
	height: 56px;
	@media screen and (max-width: 991px) {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px); } }

@mixin vAlign() {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform:  translateY(-50%);
	transform: translateY(-50%); }

@mixin tagline() {
	font-family: $openSans;
	font-size: 21px;
	color: $textBoja;
	margin: 20px auto;
	font-weight: 400;
	position: relative;
	@media screen and (max-width: 991px) {
		font-size: 20px; }
	@media screen and (max-width: 768px) {
		font-size: 18px; }
	@media screen and (max-width: 540px) {
		font-size: 17px; }
	@media screen and (max-width: 480px) {
		font-size: 16px; }
	@media screen and (max-width: 440px) {
		font-size: 15px; }
	@media screen and (max-width: 400px) {
		font-size: 14px; }
	@media screen and (max-width: 360px) {
		font-size: 13px; } }
