.kontakt-top-section {
	padding: 50px 0 40px 0;
	background-image: url(/assets/icons/delta-pozadina.svg);
	background-repeat: no-repeat;
	background-size: cover;
	@media screen and (max-width: 768px) {
		padding: 30px 0 20px 0; }
	@media screen and (max-width: 540px) {
		padding: 20px 0 10px 0; } }

.kontakt-contact {
	background-color: $siva;
	padding: 20px 0 120px 0;
	@media screen and (max-width: 991px) {
		padding: 20px 0 80px 0; } }

.map-col-img {
	@media screen and (max-width: 768px) {
		display: none; } }


.kontakt-contact .home-form {
	width: 88%;
	margin-left: 6%; }

.kontakt-contact .form-control {
	border: 1px solid lighten($ljubicasta,30%);
	&:hover,&:active, &:focus {
		border: 1px solid lighten($ljubicasta,20%); } }

.kontakt-contact #slc-prefix {
	top: 32px; }

.kontakt-forma-h {
	font-family: $openSans;
	text-transform: uppercase;
	font-size: 22px;
	margin: 50px 0;
	@media screen and (max-width: 991px) {
		margin: 30px 0;
		font-size: 21px; }
	@media screen and (max-width: 768px) {
		margin: 20px 0;
		font-size: 20px; } }

.k-h-2 {
	margin-left: 6%;
	@media screen and (max-width: 991px) {
		margin: 60px 0 50px 6%;
		line-height: 1.4; } }

ul.kontakt-list {
	width: 88%;
	margin-left: 6%;
	padding-top: 20px;
	@media screen and (max-width: 991px) {
		padding-top: 0; }
	@media screen and (max-width: 420px) {
		width: 94%;
		margin-left: 3%; }
	@media screen and (max-width: 370px) {
		width: 100%;
		margin-left: 0; } }

ul.kontakt-list li {
	border-top: 1px solid $ljubicasta;
	padding: 20px 0 20px 15px;
	@media screen and (max-width: 420px) {
		padding: 20px 0 20px 5px; } }

ul.kontakt-list li:last-child {
	border-bottom: 1px solid $ljubicasta; }


.c-l-tel, .c-l-email, .c-l-name {
	display: inline-block;
	padding: 0 5px; }

.c-l-name {
	font-weight: 500;
	font-family: $frank;
	font-size: 16px;
	@media screen and (max-width: 640px) {
		display: block;
		margin-bottom: 8px; } }

.c-l-span {
	@media screen and (max-width: 640px) {
		display: none; } }

.c-l-tel, .c-l-email {
	font-family: $openSans;
	font-size: 15px;
	font-weight: 400;
	@media screen and (max-width: 420px) {
		font-size: 14px; } }


.c-l-tel a {
	display: none;
	color: $textBoja;
	text-decoration: none;
	@media screen and (max-width: 991px) {
		display: inline; } }

.c-l-tel span {
	@media screen and (max-width: 991px) {
		display: none; } }
