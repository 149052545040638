.o-nama-hero-section {
	padding-top: 45px;
	padding-bottom: 45px;
	background-image: url(/assets/icons/delta-pozadina.svg);
	background-repeat: no-repeat;
	background-size: cover;
	@media screen and (max-width: 991px) {
		padding-top: 15px;
		margin-bottom: 15px; } }

.o-nama-par-after {
	margin: 50px 0 85px 0; }


.onama-cta-section {
	padding-bottom: 37px;
	background-color: $plava;
	margin-bottom: 2px;
	@media screen and (max-width: 991px) {
		padding-bottom: 0; }
	@media screen and (max-width: 768px) {
		padding-bottom: 15px; }
	@media screen and (max-width: 640px) {
		padding-bottom: 10px; } }

.onama-cta-line {
	@include ctaLine('../../assets/cta/plava-cta.svg'); }

.onama-cta-section h2 {
    font-family: $frank;
    font-size: 31px;
    color: #fff;
    margin: 0 auto;
    font-weight: 500;
    text-align: left;
    line-height: 1.28; }


.onama-cta-bottom {
	@include ctaButton(#fff,$plava);
	border: 2px solid $plava; }

.psihijatrjsko-lecenje-cta-bottom {
	@include ctaButton(#fff,$plava);
	margin: 0 auto;
	border: 2px solid $plava; }
