
/* -------------------------------- 
Primary style
-------------------------------- */
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body.overflow-hidden {
  /* when primary navigation is visible, the content in the background won't scroll */
  overflow: hidden;
}
/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, 
  and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 768px;
  margin: 0 auto;
}
.cd-container:after {
  content: "";
  display: table;
  clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */
html, body {
  height: 100%;
}

.cd-header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 50px;
  width: 100%;
  z-index: 3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cd-secondary-nav .konsultacija a{
  @include buttonEfects(#888,#fff);
}

.cd-secondary-nav li.active-item a, .cd-primary-nav li.active-item a{
  color:#00ff00;
}

.cd-primary-nav .footer-social-icon{
  margin: 0 4px
}

@media only screen and (min-width: 768px) {
  .cd-header {
    height: 86px;
    background: #fff;
  }
}
@media only screen and (min-width: 991px) {
  .cd-header {
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 0 8px rgba(0,0,0,0.15);
    -ms-box-shadow: 0 0 8px rgba(0,0,0,0.15);
    box-shadow: 0 0 8px rgba(0,0,0,0.15);
  }
  .cd-header.is-fixed {
    /* when the user scrolls down, we hide the header right above the viewport */
    position: fixed;
    top: -80px;
    background-color: #fff;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }
  .cd-header.is-visible {
    /* if the user changes the scrolling direction, we show the header */
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 0 8px rgba(0,0,0,0.15);
    -ms-box-shadow: 0 0 8px rgba(0,0,0,0.15);
    box-shadow: 0 0 8px rgba(0,0,0,0.15);
  }
  .cd-header.menu-is-open {
    /* add a background color to the header when the navigation is open */
    background-color: #fff;
  }
}

.cd-secondary-nav li.caret-active div{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11px 12px 11px;
  border-color: transparent transparent $plava transparent;
  margin: 0 auto;
  position: relative;
  top: 13px;
  -webkit-transform: rotate(360deg);
}
.cd-secondary-nav li.caret-active a{
  color: $plava;
}

.cd-primary-nav li.caret-active div{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 9px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
   -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}
.cd-primary-nav li.caret-active a{
  color: #fff;
  position: relative;
  left: 2px;
}

.cd-logo {
  display: block;
  width:205px; 
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: .875em;
}
.cd-logo img {
  display: block;
}
@media only screen and (min-width: 768px) {
  .cd-logo {
    left: 75px;
  }
}

.cd-secondary-nav {
  position: absolute;
  top: 54px;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 75px;
  /* hidden on small devices */
  display: none;
}
.cd-secondary-nav li {
  display: inline-block;
  margin-left: 0.6em;
}
.cd-secondary-nav a {
  display: inline-block;
  text-decoration:none;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $openSans;
  font-size:15.2px;
  padding:6px 3px;
  @include menuLink($plava); 
}
@media only screen and (min-width: 768px) {
  .cd-secondary-nav {
    display: block;
  }
}

.cd-primary-nav-trigger {
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  width: 50px;
  background-color: #fff;
  display:none;
}
.cd-primary-nav-trigger .cd-menu-text {
  color: $plava;
  text-transform: uppercase;
  font-weight: 700;
  /* hide the text on small devices */
  display: none;
}
.cd-primary-nav-trigger .cd-menu-icon {
  /* this span is the central line of the menu icon */
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: $footer;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
  /* these are the upper and lower lines in the menu icon */
}
.cd-primary-nav-trigger .cd-menu-icon::before, .cd-primary-nav-trigger .cd-menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $footer;
  right: 0;
  -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
  -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
  transition: transform .3s, top .3s, background-color 0s;
}
.cd-primary-nav-trigger .cd-menu-icon::before {
  top: -6px;
}
.cd-primary-nav-trigger .cd-menu-icon::after {
  top: 6px;
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked {
  background-color: #fff;
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before, .cd-primary-nav-trigger .cd-menu-icon.is-clicked::after{
  background-color: $footer;
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  top: 0;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}
@media only screen and (min-width: 768px) {
  .cd-primary-nav-trigger {
    width: 100px;
    padding-left: 1em;
    background-color: transparent;
    height: 30px;
    line-height: 30px;
    right: 2.2em;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .cd-primary-nav-trigger .cd-menu-text {
    display: inline-block;
  }
  .cd-primary-nav-trigger .cd-menu-icon {
    left: auto;
    right: 1em;
    -webkit-transform: translateX(0) translateY(-50%);
    -moz-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    -o-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
  }
}

.cd-primary-nav {
  /* by default it's hidden - on top of the viewport */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(105,105,112, 0.94);
  z-index: 2;
  text-align: center;
  padding: 50px 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only -
   when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.cd-primary-nav li {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: .2em 0;
  text-transform: capitalize;
}
.cd-primary-nav a {
  display: inline-block;
  padding: .4em 1em;
  border-radius: 0.25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  color: #fff;
  text-decoration: none;
  @include menuLink(#fff);
  font-weight: 300;
  font-family: $openSans;
  font-size: 20px; 
}
.no-touch .cd-primary-nav a:hover {
  background-color: #e36767;
}
.cd-primary-nav .cd-label {
  color: #06446e;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  margin: 2.4em 0 .8em;
}
.cd-primary-nav .cd-social {
  display: inline-block;
  margin: 0 .4em;
}
.cd-primary-nav .cd-social a {
  width: 44px;
  height: 44px;
  padding: 0;
  background-image: url("../img/cd-socials.svg");
  background-repeat: no-repeat;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.cd-primary-nav .cd-facebook a {
  background-position: 0 0;
}
.cd-primary-nav .cd-instagram a {
  background-position: -44px 0;
}
.cd-primary-nav .cd-dribbble a {
  background-position: -88px 0;
}
.cd-primary-nav .cd-twitter a {
  background-position: -132px 0;
}
.cd-primary-nav.is-visible {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  padding-top: 140px;
}
.cd-primary-nav .footer-social-wrap a{
  padding: 4px;
}
@media only screen and (min-width: 768px) {
  .cd-primary-nav {
    padding: 80px 0;
  }
}
@media only screen and (min-width: 991px) {
  .cd-primary-nav li {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .cd-primary-nav .cd-label {
    font-size: 16px;
    font-size: 1rem;
  }
}

.cd-intro {
  position: relative;
  height: 100%;
  background: url("../img/cd-background-img.jpg") no-repeat center center;
  background-size: cover;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cd-intro h1 {
  position: absolute;
  width: 90%;
  max-width: 1170px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  color: white;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .cd-intro h1 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media only screen and (min-width: 991px) {
  .cd-intro {
    height: 700px;
  }
}
.cd-main-content {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 991px) {

}


/* MAIN Media Queries  */

@media screen and (max-width: 1281px) {
  .cd-logo {
    left: 35px;
    width: 165px;
  }
  .cd-secondary-nav {
    right: 35px;
  }
  .cd-secondary-nav li {
    margin-left: 4px;
  }
  .cd-secondary-nav a {
    font-size: 14px;
  }

}

@media only screen and (max-width: 991px) {

   .cd-header {
      height: 80px;
      background: #fff;
      position:fixed;
  }


  .cd-secondary-nav {
      display: none;
      right: 10em;
  }

  .cd-primary-nav-trigger {
      display:block;
  }

  .cd-logo {
      width: 158px;
      left: 20px;
    }


}


@media only screen and (max-width: 768px) {

  .cd-logo {
      width: 145px;
    }

}