.sta-lecimo-hero-section {
	background-image: url(/assets/icons/delta-pozadina.svg);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 50px 0 40px 0; }

.sta-lecimo-section {
	background-color: $siva;
	@media screen and (max-width: 1280px) {
		padding-bottom: 50px; }
	@media screen and (max-width: 991px) {
		padding-bottom: 65px; } }

.sta-lecimo-section img {
	width: 100%;
	@media screen and (max-width: 1280px) {
		height: 100%; }
	@media screen and (max-width: 991px) {
		display: none; } }

.sta-lecimo-section article {
	padding: 50px 45px 0 85px;
	@media screen and (max-width: 1280px) {
		padding: 20px 30px 0 50px; }
	@media screen and (max-width: 1024px) {
		padding: 20px 30px 0 50px; }
	@media screen and (max-width: 991px) {
		padding: 20px 30px 0 80px; }
	@media screen and (max-width: 480px) {
		padding: 20px 20px 0 50px; }
	@media screen and (max-width: 400px) {
		padding: 20px 20px 0 40px; } }

.sta-lecimo-ul {
	padding-top: 20px;
	@media screen and (max-width: 1280px) {
		padding-top: 15px; } }

.sta-lecimo-ul li {
	font-size: 20px;
	font-style: italic;
	font-family: $openSans;
	list-style-type: initial;
	line-height: 1.6;
	font-weight: 400;
	@media screen and (max-width: 1280px) {
		font-size: 19px;
		line-height: 1.5; }
	@media screen and (max-width: 991px) {
		font-size: 18px;
		line-height: 1.55; }
	@media screen and (max-width: 540px) {
		font-size: 17px; }
	@media screen and (max-width: 480px) {
		font-size: 16px; }
	@media screen and (max-width: 400px) {
		font-size: 15px; }
	@media screen and (max-width: 360px) {
		font-size: 14px; } }

.sta-lecimo-cta-section {
	padding-bottom: 37px;
	background-color: $plava;
	margin-bottom: 2px;
	@media screen and (max-width: 991px) {
		padding-bottom: 0; }
	@media screen and (max-width: 768px) {
		padding-bottom: 15px; }
	@media screen and (max-width: 640px) {
		padding-bottom: 10px; } }

.sta-lecimo-cta-line {
	@include ctaLine('../../assets/cta/plava-cta.svg'); }

.sta-lecimo-cta-section h2 {
    font-family: $frank;
    font-size: 31px;
    color: #fff;
    margin: 0 auto;
    font-weight: 500;
    text-align: left;
    line-height: 1.28; }

.sta-lecimo-cta-bottom {
	@include ctaButton(#fff,$plava);
	margin: 0 auto;
	border: 2px solid $plava; }


.p-ul-naslov.tagline-p {
	@media screen and (max-width: 991px) {
		font-size: 20px; }
	@media screen and (max-width: 768px) {
		font-size: 19px; }
	@media screen and (max-width: 640px) {
		font-size: 18px; }
	@media screen and (max-width: 480px) {
		font-size: 17px; }
	@media screen and (max-width: 420px) {
		font-size: 16px; } }







