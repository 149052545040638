.psihoterapija-hero-section {
	background-image: url(/assets/icons/delta-pozadina.svg);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 50px 0 40px 0;
	@media screen and (max-width: 991px) {
		padding: 20px 0 15px 0; }
	@media screen and (max-width: 768px) {
		padding: 10px 0; } }

.psihoterapija-par-after {
	padding: 50px 0 40px 0;
	background-color: $siva;
	@media screen and (max-width: 991px) {
		padding: 40px 0 30px 0; }
	@media screen and (max-width: 768px) {
		padding: 30px 0 20px 0; }
	@media screen and (max-width: 640px) {
		padding: 20px 0; } }

.psihoterapijski-ugovor {
	margin: 40px auto 20px auto;
	background-color: #fff;
	border: 1px solid $footer;
	padding: 30px;
	@media screen and (max-width: 991px) {
		padding: 25px; }
	@media screen and (max-width: 768px) {
		padding: 20px; }
	@media screen and (max-width: 420px) {
		padding: 15px; } }

.psihoterapijski-ugovor h3 {
	margin: 20px 0 30px 0;
	font-family: $openSans;
	font-size: 22px;
	text-transform: uppercase;
	@media screen and (max-width: 991px) {
		font-size: 21px; }
	@media screen and (max-width: 768px) {
		font-size: 20px; }
	@media screen and (max-width: 420px) {
		font-size: 19px; }
	@media screen and (max-width: 380px) {
		font-size: 18px;
		margin: 18px 0 20px 0; } }

.psihoterapijski-ugovor p {
	font-style: italic;
	font-family: $openSans;
	font-size: 17px;
	color: #232126;
	margin: 15px auto;
	font-weight: 400;
	line-height: 1.5;
	@media screen and (max-width: 991px) {
		font-size: 16px;
		line-height: 1.4; }
	@media screen and (max-width: 768px) {
		font-size: 15px; }
	@media screen and (max-width: 420px) {
		font-size: 14px; }
	@media screen and (max-width: 320px) {
		font-size: 13px; } }


.psihoterapija-section img {
	width: 100%; }

.psihoterapija-section .tagline-p {
	margin: 0 auto 20px auto;
	font-weight: 600; }

.psihoterapija-section article {
	padding: 0 45px 0 85px;
	margin-top: -25px;
	@media screen and (max-width: 1280px) {
		padding: 0 35px 0 70px; }
	@media screen and (max-width: 1110px) {
		padding: 0 15px 0 60px;
		margin-top: -30px; }
	@media screen and (max-width: 991px) {
		margin: 50px 0 85px 0;
		padding: 0 35px 0 50px; }
	@media screen and (max-width: 768px) {
		margin: 40px 0 75px 0;
		padding: 0 35px 0 40px; }
	@media screen and (max-width: 640px) {
		margin: 30px 0 70px 0; }
	@media screen and (max-width: 540px) {
		margin: 20px 0 60px 0; } }

.psihoterapija-ul {}

.psihoterapija-ul li {
	font-size: 20px;
	font-style: italic;
	font-family: $openSans;
	list-style-type: initial;
	line-height: 1.6;
	font-weight: 400;
	@media screen and (max-width: 1110px) {
		font-size: 19px;
		line-height: 1.45; }
	@media screen and (max-width: 991px) {
		font-size: 18px;
		line-height: 1.55; }
	@media screen and (max-width: 540px) {
		font-size: 17px; }
	@media screen and (max-width: 480px) {
		font-size: 16px; }
	@media screen and (max-width: 400px) {
		font-size: 15px; }
	@media screen and (max-width: 360px) {
		font-size: 14px; } }
