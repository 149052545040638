//Vars and mixins import
@import "modules/variables";
@import "modules/mixins";

//Compass
//@import "compass";
//@import "compass/css3/box-shadow";


//Partial imports
@import "partials/common";
@import "partials/reset";
@import "modules/form";
@import "modules/mapa";
@import "modules/navigation";
@import "partials/pocetna";
@import "modules/footer";
@import "partials/o-nama";
@import "partials/tim";
@import "partials/sta-lecimo";
@import "partials/psihijatrijsko-lecenje";
@import "partials/psihoterapija";
@import "partials/galerija";
@import "partials/kontakt";
