.home-hero {
	position: relative; }

.hero-background {
	width: 100%; }

.hero-text {
	display: none;
	width: 42%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform:  translateY(-50%);
	transform: translateY(-50%);
	left: 29%;
	margin-top: -24px;
	@media screen and (max-width: 1100px) {
		width: 50%;
		left: 25%; }
	@media screen and (max-width: 991px) {
		width: 56%;
		left: 22%; }
	@media screen and (max-width: 768px) {
		width: 64%;
		left: 18%; }
	@media screen and (max-width: 640px) {
		width: 70%;
		left: 15%; }
	@media screen and (max-width: 540px) {
		width: 76%;
		left: 12%; }
	@media screen and (max-width: 480px) {
		width: 80%;
		left: 10%; }
	@media screen and (max-width: 440px) {
		width: 94%;
		left: 3%; } }

.hero-text.background-loaded {
	display: block; }

.cta-line.top-cta {
	@include ctaLine('../../assets/cta/pocetna-cta.svg'); }

.home-cta-section .row {
	margin-top: -47px;
	@media screen and (max-width: 1035px) {
		margin-top: -90px; }
	@media screen and (max-width: 768px) {
		margin-top: -70px; } }

.home-cta {
	@include ctaButton($plava,#fff);
	margin: 0 auto; }

.home-cta-section {
	padding-bottom: 37px;
	@media screen and (max-width: 991px) {
		padding-bottom: 15px; } }

.home-cta-section .container {
	margin-top: 0;
	@media screen and (max-width: 1185px) {
		max-width: 1100px; } }

.home-cta-section .section-heading {
	@media screen and (max-width: 768px) {
		margin: 30px 0; }
	@media screen and (max-width: 440px) {
		margin: 20px 0 30px 0; } }

.home-cta-ctn {
	text-align: center; }

.tagline-p {
	@include tagline(); }

.home-cta-section .tagline-p {
	@media screen and (max-width: 991px) {
		text-align: center; }
	@media screen and (max-width: 768px) {
		margin: 15px 0; } }

.home-median-section article {
	padding: 0 45px;
	margin-top: -15px;
	@media screen and (max-width: 1024px) {
		padding: 0 40px; }
	@media screen and (max-width: 991px) {
		padding: 0 15px; }
	@media screen and (max-width: 768px) {
		padding: 40px;
		text-align: center; }
	@media screen and (max-width: 480px) {
		padding: 30px; }
	@media screen and (max-width: 400px) {
		padding: 20px 15px; } }

.home-median-section article .tagline-p {
	@media screen and (max-width: 991px) {
		font-size: 17px; } }

.home-median-section {
	background-color: $siva; }

.home-median-section .container-fluid {
	padding: 0; }

.home-median-section img {
	width: 100%; }

.home-median-section a.home-median-cta {
	@include ctaEmptyButton($plava,$siva);
	margin-top: 27px;
	@media screen and (max-width: 991px) {
		margin-top: 10px; } }

.home-median-l {
	background-image: url('/assets/icons/delta-pozadina.svg');
	background-repeat: no-repeat;
	background-size: cover;
	padding: 70px 0 120px 0;
	@media screen and (max-width: 768px) {
		padding: 30px 0 80px 0; } }

.home-median-l-h {
	@include naslovSekcijeSmall($textBoja);
	text-align: center;
	margin-bottom: 50px;
	@media screen and (max-width: 991px) {
		margin-bottom: 40px;
		font-size: 31px; }
	@media screen and (max-width: 768px) {
		font-size: 28px; } }

.home-median-l .col-md-4 {
	border-right: 2px solid $footer;
	&:last-child {
		border-right: none; }
	@media screen and (max-width: 768px) {
		border-right: none;
		border-bottom: 2px solid $footer;
		padding: 15px 0;
		&:last-child {
			border-bottom: none; } } }

.lecenje-median li {
	color: $textBoja;
	padding: 10px;
	font-family: $openSans;
	font-size: 17.5px;
	text-transform: uppercase;
	line-height: 1.18;
	@media screen and (max-width: 991px) {
		font-size: 16.2px; }
	@media screen and (max-width: 768px) {
		font-size: 16px;
		text-align: center; }
	@media screen and (max-width: 480px) {
		font-size: 15px;
		padding: 5px;
		line-height: 1.23; } }

.lecenje-md.lecenje-median li {
	padding: 10px 10px 10px 25px;
	@media screen and (max-width: 991px) {
		padding: 5px 5px 5px 20px; }
	@media screen and (max-width: 768px) {
		padding: 10px; } }

.home-cta-section-bottom {
	background-color: $ljubicasta; }

.cta-line.bottom-cta {
	@include ctaLine('../../assets/cta/ljubicasta-cta.svg'); }

.home-cta-bottom {
	@include ctaButton(#fff,$ljubicasta);
	border: 2px solid $footer; }


.home-contact .container-fluid {
	padding: 0; }

.home-contact-img {
	width: 100%;
	@media screen and (max-width: 1024px) {
		width: auto;
		height: 100%; }
	@media screen and (max-width: 991px) {
		margin-left: -5%; }
	@media screen and (max-width: 850px) {
		margin-left: -10%; } }

.home-contact-img-ctn {
	@media screen and (max-width: 1024px) {
		overflow: hidden; }
	@media screen and (max-width: 768px) {
		display: none; } }

.home-contact h2 {
	text-align: left;
	margin-bottom: 25px; }

.home-form {
	width: 85%;
	margin-left: 10%; }
