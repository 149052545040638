body, body p {
	font-family: $openSans;
	font-size: 19px;
	font-weight: 300;
	line-height: 1.425;
	color: $textBoja;
	@media screen and (max-width: 991px) {
		font-size: 18px; }
	@media screen and (max-width: 768px) {
		font-size: 16px; }
	@media screen and (max-width: 480px) {
		font-size: 14px; } }

.section-heading {
	@include naslovSekcije(); }

.tagline-p {
	@include tagline(); }

.info-blue {
	@include infoBackground('../../img/patterns/talasi-plavi.svg');
	position: relative; }

.info-green {
	@include infoBackground('../../img/patterns/talasi-zeleni.svg');
	position: relative; }

.page-heading h2 {
	font-size: 23px;
	font-family: $frank;
	padding: 18px 0;
	background-color: $ljubicasta;
	text-align: center;
	color: #fff;
	margin-top: 80px;
	text-transform: uppercase;
	@media screen and (max-width: 991px) {
		font-size: 22px;
		padding: 17px 0; }
	@media screen and (max-width: 768px) {
		font-size: 22px;
		padding: 15px 0; }
	@media screen and (max-width: 480px) {
		font-size: 22px;
		padding: 14px 0; } }

.tagline-p.start-arr .p-arr {
	display: inline-block;
	top: 0.305em;
	left: -21px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 9px 0 9px 12px;
	border-color: transparent transparent transparent $plava;
	position: absolute;
	@media screen and (max-width: 540px) {
		border-width: 7px 0 7px 10px;
		left: -14px; }
	@media screen and (max-width: 480px) {
		top: 0.3em;
		left: -11px;
		border-width: 6px 0 6px 9px; } }


.displ-none {
	display: none !important; }

.top-section {
	margin-top: 86px;
	@media screen and (max-width: 991px) {
		margin-top: 80px; }
	@media screen and (max-width: 991px) {
		margin-top: 70px; } }

.cta-section-mq {
	padding-bottom: 37px;
	@media screen and (max-width: 991px) {
		padding-bottom: 0; }
	@media screen and (max-width: 768px) {
		padding-bottom: 15px; }
	@media screen and (max-width: 640px) {
		padding-bottom: 10px; } }


.cta-section-mq .container {
	@media screen and (max-width: 991px) {
		max-width: 900px; } }


.cta-section-mq h2.home-median-l-h {
	@include naslovSekcijeSmall(#fff);
	text-align: left;
	line-height: 1.28;
	@media screen and (max-width: 1024px) {
		font-size: 27px; }
	@media screen and (max-width: 991px) {
		font-size: 25px; }
	@media screen and (max-width: 820px) {
		font-size: 24px; }
	@media screen and (max-width: 768px) {
		font-size: 23px;
		text-align: center;
		margin: 40px 0 10px 0;
		padding: 5px 2px; }
	@media screen and (max-width: 640px) {
		font-size: 22px; }
	@media screen and (max-width: 540px) {
		font-size: 21px;
		margin: 40px 0 5px 0; }
	@media screen and (max-width: 480px) {
		font-size: 20px; } }

.cta-section-mq .container {
	@media screen and (max-width: 768px) {
		padding-left: 10px;
		padding-right: 10px; } }


.cta-section-mq .row {
	margin-top: -48px;
	@media screen and (max-width: 991px) {
		margin-top: -90px; }
	@media screen and (max-width: 768px) {
		margin-top: -70px; }
	@media screen and (max-width: 648px) {
		margin-top: -85px; } }

.cta-section-mq .cta-bottom {
	margin: 0 auto;
	@media screen and (max-width: 1200px) {
		font-size: 17px; }
	@media screen and (max-width: 480px) {
		font-size: 16px; } }


.parallax-window {
	height: 350px;
	@media screen and (max-width: 991px) {
		height: 320px; }
	@media screen and (max-width: 768px) {
		height: 280px; }
	@media screen and (max-width: 640px) {
		height: 250px; } }

#home-hero .parallax-window {
	height: 620px;
	@media screen and (max-width: 991px) {
		height: 450px; }
	@media screen and (max-width: 768px) {
		height: 400px; }
	@media screen and (max-width: 640px) {
		height: 390px; } }

main.cd-main-content {
	@media screen and (max-width: 768px) {
		overflow-x: hidden; } }

.home-median-l, .o-nama-hero-section, .sta-lecimo-hero-section, .psihijatrjsko-lecenje-par-after, .psihoterapija-hero-section, .kontakt-top-section {
	@media screen and (max-width: 991px) {
		background-position: center center; } }


.cd-main-content.top-section {
	max-width: 1378px;
	margin-left: auto;
	margin-right: auto;
	@media screen and (min-width: 1370px) {
		border-left: 1px solid lighten($footer, 40%);
		border-right: 1px solid lighten($footer, 40%); } }


