.psihijatrijsko-lecenje-top-section {
	background-repeat: no-repeat;
	background-size: cover;
	padding: 50px 0 40px 0;
	@media screen and (max-width: 991px) {
		padding: 20px 0 15px 0; }
	@media screen and (max-width: 768px) {
		padding: 10px 0; } }


.psihijatrjsko-lecenje-section {
	background-color: $siva; }

.psihijatrjsko-lecenje-section img {
	width: 100%; }

.psihijatrjsko-lecenje-section article {
	padding: 0 45px;
	@media screen and (max-width: 991px) {
		padding: 0 15px; } }

.psihijatrjsko-lecenje-section article p {
	line-height: 1.5;
	@media screen and (max-width: 1280px) {
		font-size: 20px; }
	@media screen and (max-width: 1100px) {
		font-size: 19px;
		line-height: 1.35; }
	@media screen and (max-width: 991px) {
		line-height: 1.45;
		font-size: 20px; }
	@media screen and (max-width: 768px) {
		font-size: 18px; }
	@media screen and (max-width: 540px) {
		font-size: 17px; }
	@media screen and (max-width: 480px) {
		font-size: 16px; }
	@media screen and (max-width: 440px) {
		font-size: 15px; }
	@media screen and (max-width: 400px) {
		font-size: 14px; }
	@media screen and (max-width: 360px) {
		font-size: 13px; } }

.psihijatrjsko-lecenje-par-after {
	padding: 50px 0 85px 0;
	background-image: url(/assets/icons/delta-pozadina.svg);
	background-repeat: no-repeat;
	background-size: cover; }

.psihijatrjsko-lecenje-cta-section {
	background-color: $ljubicasta;
	padding-bottom: 37px;
	margin-bottom: 2px;
	@media screen and (max-width: 991px) {
		padding-bottom: 0; }
	@media screen and (max-width: 768px) {
		padding-bottom: 15px; }
	@media screen and (max-width: 640px) {
		padding-bottom: 10px; } }

.psihijatrjsko-lecenje-cta-line {
	@include ctaLine('../../assets/cta/ljubicasta-cta.svg'); }

.psihijatrjsko-lecenje-cta-section h2 {
    font-family: $frank;
    font-size: 31px;
    color: #fff;
    margin: 0 auto;
    font-weight: 500;
    text-align: left;
    line-height: 1.28; }

.psihijatrjsko-lecenje-cta-bottom {
	@include ctaButton(#fff,$ljubicasta);
	margin: 0 auto;
	border: 2px solid $ljubicasta; }
