.gallery-grid-section {
	padding: 60px 0 120px 0;
	@media screen and (max-width: 991px) {
		padding: 20px 0 80px 0; }
	@media screen and (max-width: 768px) {
		padding: 10px 0 80px 0; } }

.gallery-grid-section h2.section-heading {
	margin-bottom: 50px;
	padding-left: 10px;
	font-size: 44px;
	@media screen and (max-width: 991px) {
		font-size: 28px;
		padding-left: 0;
		margin-bottom: 30px; }
	@media screen and (max-width: 768px) {
		font-size: 28px; }
	@media screen and (max-width: 480px) {
		font-size: 26px; } }

.gallery-grid-section figure {
	overflow: hidden;
	text-align: center;
	cursor: pointer;
	position: relative; }

.gallery-grid-section img {
	width: 100%; }

.gallery-grid-section figure h2 {
	font-family: $openSans;
	font-weight: 600;
	color: #fff;
	font-size: 23px; }

.gallery-grid-section .effect-ruby figcaption {
	top: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
	width: auto;
	height: auto; }

.galerija-cta-section {
	background-color: $ljubicasta;
	padding-bottom: 37px;
	margin-bottom: 2px;
	@media screen and (max-width: 991px) {
		padding-bottom: 0; }
	@media screen and (max-width: 768px) {
		padding-bottom: 15px; }
	@media screen and (max-width: 640px) {
		padding-bottom: 10px; } }

.galerija-cta-line {
	@include ctaLine('../../assets/cta/ljubicasta-cta.svg'); }

.galerija-cta-section h2 {
    font-family: $frank;
    font-size: 31px;
    color: #fff;
    margin: 0 auto;
    font-weight: 500;
    text-align: left;
    line-height: 1.28; }


.pswp__caption__center {
	display: none; }
