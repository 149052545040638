//Colors
$bela: #FCFCFC;
$plava: #0AB7C9;
$ljubicasta: #7D618C;
$siva: #F3EEFF;
$footer: #696970;
$textBoja: #232126;

//Fonts
$openSans: 'Open Sans', sans-serif;
$frank: 'Frank Ruhl Libre', serif;

//Button Paddings
$smallBtn: 5px 10px;
$mediumBtn: 9px 21px;
$largeBtn: 12px 26px;
$ctaLarge: 10px 35px;

