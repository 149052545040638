.form-group {
	position: relative;
	padding-top: 20px;
	margin-bottom: 0.5rem; }

.form-control {
	border-radius: 2px;
	color: $textBoja;
	border: 1px solid lighten($ljubicasta,30%);
	font-family: $openSans;
	font-size: 15px;
	&:hover,&:active, &:focus {
		border: 1px solid lighten($ljubicasta,18%);
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		-ms-box-shadow: none;
		box-shadow: none; }
	@media screen and (max-width: 768px) {
		font-size: 14px; } }

.slc-form-group {
	position: relative; }

select.form-control option {
	color: lighten($textBoja,10%);
	font-family: $openSans;
	font-size: 15px;
	padding: 5px 2px;
	&:hover,&:active, &:focus {
		background-color: $footer; }
	@media screen and (max-width: 768px) {
		font-size: 15px; } }

#slc-prefix {
	position: absolute;
	top: 32px;
	display: inline-block;
	left: 15px;
	font-family: $openSans;
	font-size: 15.5px;
	color: lighten($textBoja,15%);
	font-style: italic;
	@media screen and (max-width: 768px) {
		font-size: 15px;
		left: 14px;
		top: 32px; } }

#select-dest {
	text-indent: 80px;
	color: $plava;
	font-weight: 600;
	font-size: 15.5px;
	@media screen and (max-width: 768px) {
		font-size: 15px;
		text-indent: 76px; } }


.form-control::-webkit-input-placeholder {
	color: lighten($textBoja,25%);
	font-family: $openSans;
	font-size: 15.5px;
	font-style: italic; }

.form-control::-moz-placeholder {
	color: lighten($textBoja,25%);
	font-family: $openSans;
	font-size: 15.5px;
	font-style: italic; }

.form-control:-ms-input-placeholder {
	color: lighten($textBoja,25%);
	font-family: $openSans;
	font-size: 15.5px;
	font-style: italic; }

.form-control:-moz-placeholder {
	color: lighten($textBoja,25%);
	font-family: $openSans;
	font-size: 15.5px;
	font-style: italic; }

.submit-ctn {
	text-align: right; }

#form-submit {
	background-color: #fff;
	@include ctaEmptyButton($plava,#fff);
	margin: 20px 0;
	cursor: pointer;
	padding: 9px 38px; }

.err-msg {
	display: none; }

.input-error .err-msg {
	display: inline-block;
	font-weight: 600;
	color: #EF566D;
	font-size: 11.8px;
	margin-top: 0;
	left: 0;
	margin-bottom: 5px;
	position: absolute;
	top: 4px; }

.form-group.col-sm-6 .err-msg {
	left: .95rem; }


.form-group.input-error .form-control {
	border: 1px solid #EF566D; }

.form-group.input-error input.form-control:focus {
	border: 1px solid #EF566D;
	box-shadow: inset 0px 0px 0px 1px rgba(239,86,109,0.2); }


.form-group.input-error .form-control::-webkit-input-placeholder {
	color: #EF566D;
	font-family: $openSans;
	font-size: 16px; }

.form-group.input-error .form-control::-moz-placeholder {
	color: #EF566D;
	font-family: $openSans;
	font-size: 16px; }

.form-group.input-error .form-control:-ms-input-placeholder {
	color: #EF566D;
	font-family: $openSans;
	font-size: 16px; }

.form-group.input-error .form-control:-moz-placeholder {
	color: #EF566D;
	font-family: $openSans;
	font-size: 16px; }



#form-submit.mail-resp-await {
	background-color: $plava;
	color: #fff;
	background-image: url(/assets/icons/gif-load.gif);
	background-repeat: no-repeat;
	background-size: auto 100%;
	padding-left: 45px; }


#form-submit.mail-resp-succ {
	background-color: $plava;
	color: #fff; }
