.si-float-wrapper {
  position: absolute;
  width: 100%; }
  .si-float-wrapper,
  .si-float-wrapper * {
    box-sizing: border-box; }

[class*='si-wrapper'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  cursor: default; }

.si-wrapper-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: -40px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%); }

.si-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.si-wrapper-left {
  margin-top: -20px;
  margin-left: -11px;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%); }

.si-wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-top: -20px;
  margin-left: 11px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

[class*='si-shadow-wrapper'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.29804;
  z-index: 1; }

.si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.si-shadow-pointer-bottom,
.si-shadow-pointer-right {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.si-shadow-frame {
  box-shadow: 0 1px 3px 0 #000; }

[class*='si-shadow-pointer'] {
  position: relative;
  width: 15px;
  height: 15px;
  margin: auto; }

[class*='si-shadow-inner-pointer'] {
  position: absolute;
  width: 141%;
  height: 141%;
  box-shadow: -0.70711px 0.70711px 3px 0 #000; }

.si-shadow-inner-pointer-top {
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(-45deg);
          transform: translate(-50%, 50%) rotate(-45deg); }

.si-shadow-inner-pointer-left {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-right {
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
          transform: translate(50%, -50%) rotate(-45deg); }

.si-frame {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2; }

.si-content-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 30px;
  background-color: #fff; }
  .si-has-border .si-content-wrapper {
    border: 1px solid #bbb; }

.si-content {
  overflow: auto; }

.si-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.5;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .si-close-button:hover, .si-close-button:focus {
    opacity: 0.7; }

[class*='si-pointer-border'] {
  position: absolute;
  border: 15px solid transparent;
  z-index: 3; }

[class*='si-pointer-bg'] {
  position: relative;
  border: 15px solid transparent;
  z-index: 4; }
  .si-has-border [class*='si-pointer-bg'] {
    border-width: 15px; }

.si-pointer-border-top,
.si-pointer-border-bottom {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.si-pointer-border-left,
.si-pointer-border-right {
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.si-pointer-top {
  border-bottom: 0; }

.si-pointer-border-top {
  bottom: 0;
  border-top-color: #bbb; }

.si-pointer-bg-top {
  border-top-color: #fff; }
  .si-has-border .si-pointer-bg-top {
    top: -1px;
    margin-bottom: 0px; }

.si-pointer-bottom {
  border-top: 0; }

.si-pointer-border-bottom {
  top: 0;
  border-bottom-color: #bbb; }

.si-pointer-bg-bottom {
  border-bottom-color: #fff; }
  .si-has-border .si-pointer-bg-bottom {
    bottom: -1px;
    margin-top: 0px; }

.si-pointer-left {
  border-right: 0; }

.si-pointer-border-left {
  right: 0;
  border-left-color: #bbb; }

.si-pointer-bg-left {
  border-left-color: #fff; }
  .si-has-border .si-pointer-bg-left {
    left: -1px;
    margin-right: 0px; }

.si-pointer-right {
  border-left: 0; }

.si-pointer-border-right {
  left: 0;
  border-right-color: #bbb; }

.si-pointer-bg-right {
  border-right-color: #fff; }
  .si-has-border .si-pointer-bg-right {
    right: -1px;
    margin-left: 0px; }










.si-float-wrapper {
  position: absolute;
  width: 100%; }
  .si-float-wrapper,
  .si-float-wrapper * {
    box-sizing: border-box; }

[class*='si-wrapper'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.4rem;
  cursor: default; }

.si-wrapper-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: -40px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%); }

.si-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.si-wrapper-left {
  margin-top: -20px;
  margin-left: -11px;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%); }

.si-wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-top: -20px;
  margin-left: 11px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

[class*='si-shadow-wrapper'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  z-index: 1; }

.si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.si-shadow-pointer-bottom,
.si-shadow-pointer-right {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.si-shadow-frame {
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.7); }

[class*='si-shadow-pointer'] {
  position: relative;
  width: 15px;
  height: 15px;
  margin: auto; }

[class*='si-shadow-inner-pointer'] {
  position: absolute;
  width: 141%;
  height: 141%;
  box-shadow: -2.82843px 2.82843px 6px 0 #000; }

.si-shadow-inner-pointer-top {
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(-45deg);
          transform: translate(-50%, 50%) rotate(-45deg); }

.si-shadow-inner-pointer-left {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-right {
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
          transform: translate(50%, -50%) rotate(-45deg); }

.si-frame {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  border-radius: 0;
  overflow: hidden;
  z-index: 2; }

.si-content-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  background-color: #fff;
  border: 1px solid #696970;
  padding: 0 80px 0 20px; }
  .si-has-border .si-content-wrapper {
    border: 1px solid #bbb; }

.si-content {
  overflow: auto; }

.si-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.5;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .si-close-button:hover, .si-close-button:focus {
    opacity: 0.7; }

[class*='si-pointer-border'] {
  position: absolute;
  border: 15px solid transparent;
  z-index: 3; }

[class*='si-pointer-bg'] {
  position: relative;
  border: 15px solid transparent;
  z-index: 4; }
  .si-has-border [class*='si-pointer-bg'] {
    border-width: 15px; }

.si-pointer-border-top,
.si-pointer-border-bottom {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.si-pointer-border-left,
.si-pointer-border-right {
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.si-pointer-top {
  border-bottom: 0; }

.si-pointer-border-top {
  bottom: 0;
  border-top-color: #bbb; }

.si-pointer-bg-top {
  border-top-color: #fff; 
  position: relative;
  top: -2px;
}
  .si-has-border .si-pointer-bg-top {
    top: -1px;
    margin-bottom: 0px; }

.si-pointer-bottom {
  border-top: 0; }

.si-pointer-border-bottom {
  top: 0;
  border-bottom-color: #bbb; }

.si-pointer-bg-bottom {
  border-bottom-color: #eee; }
  .si-has-border .si-pointer-bg-bottom {
    bottom: -1px;
    margin-top: 0px; }

.si-pointer-left {
  border-right: 0; }

.si-pointer-border-left {
  right: 0;
  border-left-color: #bbb; }

.si-pointer-bg-left {
  border-left-color: #eee; }
  .si-has-border .si-pointer-bg-left {
    left: -1px;
    margin-right: 0px; }

.si-pointer-right {
  border-left: 0; }

.si-pointer-border-right {
  left: 0;
  border-right-color: #bbb; }

.si-pointer-bg-right {
  border-right-color: #eee; }
  .si-has-border .si-pointer-bg-right {
    right: -1px;
    margin-left: 0px; }

.custom-window {
  top: 30px;
  max-width: 400px;
  -webkit-transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden; }
  .custom-window.open .si-frame {
    overflow: visible; }
  .custom-window.active {
    top: 0;
    opacity: 1;
    visibility: visible; }
  .custom-window .si-content-wrapper {
    padding-bottom: 10px; }
  .custom-window .si-content {
    overflow: visible; }

.custom-content {
  position: relative;
  max-height: inherit;
  padding-top: 120px;
  overflow: auto;
  z-index: 2; }

.custom-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1; }

.custom-header {
  position: relative;
  margin: 0;
  padding: 30px;
  background-color: #44433e;
  color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3); }
  .custom-header small {
    display: block;
    font-size: 0.55em;
    font-weight: 400;
    opacity: 0.75; }

.custom-body {
  padding: 30px;
  background-color: #fff; }
  .custom-body p {
    margin: 0; }
  .custom-body * + p {
    margin-top: 1em; }
  .custom-body a {
    color: #60a295; }
    .custom-body a:hover, .custom-body a:focus, .custom-body a:active {
      color: #437369; }

.custom-close {
  position: absolute;
  top: -1px;
  right: -36px;
  width: 36px;
  height: 36px;
  -webkit-transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  border: 0;
  background-color: rgba(105,105,112, 0.8);
  color: #fff;
  font-size: 1em;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer; }
  .custom-close:hover, .custom-close:focus, .custom-close:active {
    background-color: rgba(105,105,112, 0.98); }

@media (min-width: 992px) {
  .custom-window {
    max-width: 500px; }
  .custom-content {
    padding-top: 160px; }
  .custom-img {
    height: 160px; }
  .custom-header,
  .custom-body {
    padding: 40px; } }

/*# sourceMappingURL=styles.css.map */



.info-img {
  max-width: 160px !important;
}

.mapa-contact {
  font-family: 'Open Sans', sans-serif;
  color: #232126;
  line-height: 1.2;
}

.mapa-adresa {
  font-weight: 600;
  font-size: 17px;
}

.mapa-email-tel {
  font-size: 15px;
  color: #7D618C;
}


@media (max-width: 768px) {
  
  .info-img {
    max-width: 150px !important;
  }
  .mapa-adresa {
  font-size: 15px;
  }

  .mapa-email-tel {
   font-size: 14px;
  }


}





















