.iziModal-wrap {
  height: 100% !important; }

.iziModal-content {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center; }

.mdl-wrap {
  position: relative;
  overflow: auto;
  max-height: 100%; }

.mdl-content-wrap {
  position: relative;
  width: 70%;
  padding: 10px 0;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 80%; }
  @media screen and (max-width: 1024px) {
    width: 90%; }
  @media screen and (max-width: 600px) {
    padding: 70px 0 10px 0; }
  @media screen and (max-width: 400px) {
    padding: 70px 0 10px 0; } }

.mdl-name {
  font-family: $frank;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
  @media screen and (max-width: 991px) {
    font-size: 27px; }
  @media screen and (max-width: 768px) {
    font-size: 25px; }
  @media screen and (max-width: 640px) {
    font-size: 24px; }
  @media screen and (max-width: 540px) {
    font-size: 23px; }
  @media screen and (max-width: 480px) {
    font-size: 22px; }
  @media screen and (max-width: 450px) {
    font-size: 21px; } }

.mdl-tagline {
  text-transform: uppercase;
  font-family: $openSans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 15px; }
  @media screen and (max-width: 540px) {
    font-size: 14px; }
  @media screen and (max-width: 450px) {
    font-size: 13px; } }

.mdl-wrap p {
  border-top: 1px solid rgba(255,255,255,0.6);
  border-bottom: 1px solid rgba(255,255,255,0.6);
  padding: 20px 0;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.8;
  color: #fff;
  @media screen and (max-width: 1024px) {
    font-size: 16px; }
  @media screen and (max-width: 991px) {
    font-size: 15.5px; }
  @media screen and (max-width: 768px) {
    font-size: 14px; }
  @media screen and (max-width: 640px) {
    font-size: 13.5px; }
  @media screen and (max-width: 540px) {
    font-size: 13px; }
  @media screen and (max-width: 480px) {
    font-size: 12.5px; }
  @media screen and (max-width: 440px) {
    font-size: 12px; }
  @media screen and (max-width: 350px) {
    font-size: 11.5px; } }


.close-mdl {
  position: fixed;
  display: block;
  top: 15px;
  right: 20px;
  color: rgba(255,255,255,0.8);
  font-family: $frank;
  font-size: 68px;
  font-weight: 400;
  transform: rotate(45deg);
  text-decoration: none !important;
  &:hover,&:active,&:focus {
    color: rgba(255,255,255,1); }
  @media screen and (max-width: 768px) {
    font-size: 60px; }
  @media screen and (max-width: 400px) {
    top: 8px;
    font-size: 50px; } }

.mdl-email, .mdl-tel {
  display: inline-block;
  font-family: $openSans;
  font-size: 20px;
  font-weight: 400;
  padding: 15px 35px 0 30px;
  position: relative;
  color: #fff;
  @media screen and (max-width: 991px) {
    padding: 15px 25px 0 28px;
    font-size: 19px; }
  @media screen and (max-width: 768px) {
    padding: 15px 25px 0 28px;
    font-size: 17.2px; }
  @media screen and (max-width: 600px) {
    padding: 12px 25px 12px 25px; }
  @media screen and (max-width: 530px) {
    font-size: 16px;
    padding: 10px 0 10px 24px;
    display: block; }
  @media screen and (max-width: 490px) {
    font-size: 15px;
    padding: 10px 0 10px 22px; }
  @media screen and (max-width: 460px) {
    font-size: 14px;
    padding-left: 20px; }
  @media screen and (max-width: 435px) {
    font-size: 13.6px;
    padding-left: 19px; }
  @media screen and (max-width: 390px) {
    font-size: 12.8px;
    padding: 7px 0 7px 20px; }
  @media screen and (max-width: 365px) {
    font-size: 12px; } }


.mdl-tel {
  @media screen and (max-width: 530px) {
    padding-top: 20px; }
  @media screen and (max-width: 390px) {
    padding-top: 15px; } }

.mdl-email .img-email-icon {
  background: url('/assets/icons/pismo-popup.svg');
  background-repeat: no-repeat;
  top: 15px;
  left: -4px;
  width: 29px;
  height: 29px;
  @media screen and (max-width: 1280px) {
    width: 26px;
    height: 26px;
    top: 16px;
    left: -3px; }
  @media screen and (max-width: 991px) {
    width: 25px;
    height: 25px;
    left: -2px; }
  @media screen and (max-width: 768px) {
    top: 16px;
    left: 0px;
    width: 24px;
    height: 24px; }
  @media screen and (max-width: 600px) {
    top: 14px;
    left: -2px;
    width: 23px;
    height: 23px; }
  @media screen and (max-width: 530px) {
    top: 10px;
    left: -3px; }
  @media screen and (max-width: 490px) {
    width: 20px;
    height: 20px; }
  @media screen and (max-width: 460px) {
    width: 18px;
    height: 18px; }
  @media screen and (max-width: 390px) {
    width: 17px;
    height: 17px;
    top: 8px;
    left: -1px; } }


.mdl-tel .img-tel-icon {
  background: url('/assets/icons/tel-popup.svg');
  background-repeat: no-repeat;
  top: 13px;
  left: -4px;
  width: 29px;
  height: 29px;
  @media screen and (max-width: 1280px) {
    width: 26px;
    height: 26px;
    top: 14px;
    left: -3px; }
  @media screen and (max-width: 991px) {
    width: 25px;
    height: 25px;
    left: -2px; }
  @media screen and (max-width: 768px) {
    top: 14px;
    left: 0px;
    width: 24px;
    height: 24px; }
  @media screen and (max-width: 600px) {
    top: 12px;
    left: -2px;
    width: 23px;
    height: 23px; }
  @media screen and (max-width: 530px) {
    top: 18px;
    left: -3px; }
  @media screen and (max-width: 490px) {
    width: 20px;
    height: 20px; }
  @media screen and (max-width: 460px) {
    width: 18px;
    height: 18px; }
  @media screen and (max-width: 390px) {
    width: 17px;
    height: 17px;
    top: 14px;
    left: -1px; } }

a.mdl_link {
  color: #fff;
  text-decoration: none;
  &:hover, &:active, &:visited, &:focus {
    text-decoration: underline; } }


.tim-section {
  padding-top: 50px;
  padding-bottom: 120px;
  background-color: $bela;
  @media screen and (max-width: 991px) {
    padding-top: 40px; } }

.tim-section .section-heading {
  margin-bottom: 40px;
  @media screen and (max-width: 991px) {
    margin-top: 0; } }

.tim-section .container {
  @media screen and (max-width: 991px) {
    max-width: 920px; } }

.tim-section .col-7 .col-5, .tim-section .col-7 .col-7 {
  @media screen and (max-width: 850px) {
    padding: 0; } }

.tim-m {
  border-top: 1px solid rgba(105,105,112,0.6);
  border-left: 1px solid rgba(105,105,112,0.6);
  border-right: 1px solid rgba(105,105,112,0.6);
  padding: 25px;
  background-color: #fff; }

.tim-m.tim-m-last {
  border-bottom: 1px solid rgba(105,105,112,0.6); }

.tim-m .col-6.col-md-5 {
  @media screen and (max-width: 550px) {
    padding-left: 6px; }
  @media screen and (max-width: 480px) {
    padding-left: 3px; } }

.tim-m-h {
  font-family: $frank;
  font-size: 24px;
  font-weight: 500;
  color: $textBoja;
  margin-bottom: 10px;
  @media screen and (max-width: 1280px) {
    font-size: 23px; }
  @media screen and (max-width: 991px) {
    font-size: 22px; }
  @media screen and (max-width: 768px) {
    font-size: 21px; }
  @media screen and (max-width: 640px) {
    font-size: 20px; }
  @media screen and (max-width: 550px) {
    font-size: 19px; }
  @media screen and (max-width: 480px) {
    font-size: 18px; }
  @media screen and (max-width: 450px) {
    font-size: 17px; }
  @media screen and (max-width: 420px) {
    font-size: 16px; }
  @media screen and (max-width: 350px) {
    font-size: 15px; } }

.tim-m-tagline {
  text-transform: uppercase;
  font-family: $openSans;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 18px; }
  @media screen and (max-width: 640px) {
    font-size: 13px;
    margin-bottom: 15px; }
  @media screen and (max-width: 550px) {
    font-size: 12px;
    margin-bottom: 12px; }
  @media screen and (max-width: 480px) {
    font-size: 11px;
    margin-bottom: 10px; }
  @media screen and (max-width: 420px) {
    font-size: 10px;
    margin-bottom: 8px; }
  @media screen and (max-width: 350px) {
    font-size: 9px; } }

.trigger {
  font-size: 16.5px;
  font-weight: 600;
  font-family: $openSans;
  &:hover, &:active, &:visited, &:focus {
    text-decoration: none;
    color: $footer; }
  color: $plava;
  @media screen and (max-width: 991px) {
    font-size: 16px;
    text-align: center; }
  @media screen and (max-width: 768px) {
    font-size: 15px; }
  @media screen and (max-width: 540px) {
    font-size: 14px; }
  @media screen and (max-width: 440px) {
    font-size: 13px; } }

.trigger .ion-chevron-right {
  font-size: 14px;
  @media screen and (max-width: 768px) {
    font-size: 13px; }
  @media screen and (max-width: 540px) {
    font-size: 12px; } }

.img-tel-icon {
  background: url('/assets/icons/tel.svg');
  width: 29px;
  height: 29px;
  display: inline-block;
  position: absolute;
  top: -5px;
  left: -1px;
  background-repeat: no-repeat;
  @media screen and (max-width: 991px) {
    width: 25px;
    height: 25px; }
  @media screen and (max-width: 768px) {
    top: 10px;
    left: 12px;
    width: 24px;
    height: 24px; }
  @media screen and (max-width: 600px) {
    left: -8px; }
  @media screen and (max-width: 530px) {
    top: 7px; }
  @media screen and (max-width: 490px) {
    width: 20px;
    height: 20px; }
  @media screen and (max-width: 460px) {
    width: 18px;
    height: 18px; } }

.img-email-icon {
  background: url('/assets/icons/pismo.svg');
  width: 29px;
  height: 29px;
  display: inline-block;
  position: absolute;
  top: -4px;
  left: -3px;
  background-repeat: no-repeat;
  @media screen and (max-width: 991px) {
    width: 25px;
    height: 25px; }
  @media screen and (max-width: 768px) {
    top: 12px;
    left: 11px;
    width: 24px;
    height: 24px; }
  @media screen and (max-width: 600px) {
    top: 12px;
    left: -8px;
    width: 22px;
    height: 22px; }
  @media screen and (max-width: 530px) {
    top: 8px; }
  @media screen and (max-width: 490px) {
    width: 20px;
    height: 20px; }
  @media screen and (max-width: 460px) {
    width: 18px;
    height: 18px; } }

.tim-m-tel, .tim-m-email {
  font-family: $openSans;
  font-size: 19px;
  font-weight: 400;
  padding-left: 33px;
  position: relative;
  text-align: left;
  @media screen and (max-width: 1280px) {
    font-size: 18.5px; }
  @media screen and (max-width: 1024px) {
    font-size: 18px; }
  @media screen and (max-width: 991px) {
    font-size: 17px;
    padding-left: 28px; }
  @media screen and (max-width: 790px) {
    font-size: 15.4px;
    padding-left: 26px; }
  @media screen and (max-width: 768px) {
    padding: 15px 0 15px 40px; }
  @media screen and (max-width: 600px) {
    padding: 12px 0 12px 20px; }
  @media screen and (max-width: 530px) {
    font-size: 14px;
    padding: 10px 0 10px 20px; }
  @media screen and (max-width: 490px) {
    font-size: 13px; }
  @media screen and (max-width: 460px) {
    font-size: 12px;
    padding-left: 13px; }
  @media screen and (max-width: 435px) {
    font-size: 11px; }
  @media screen and (max-width: 390px) {
    font-size: 10px; }
  @media screen and (max-width: 365px) {
    font-size: 9px; } }



.tim-saradnici {
  font-family: $openSans;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 40px 0 15px 5px;
  @media screen and (max-width: 991px) {
    font-size: 19px;
    text-align: center; }
  @media screen and (max-width: 768px) {
    font-size: 18px; }
  @media screen and (max-width: 440px) {
    font-size: 17px; } }

.tim-cta-line {
  @include ctaLineCenter('../../assets/cta/nas-tim-cta-2.svg'); }

.tim-cta-section {
  background-color: $ljubicasta;
  margin-bottom: 2px; }

.tim-cta-section h2 {
  color: #fff;
  text-align: center !important;
  line-height: 1.38 !important; }
